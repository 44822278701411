import _react3 from "react";
import _propTypes3 from "prop-types";
import _reactcss3 from "reactcss";
import _map3 from "lodash/map";
import _merge3 from "lodash/merge";
import _color2 from "../../helpers/color";
import _common2 from "../common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Twitter = undefined;
var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _propTypes = _propTypes3;

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactcss = _reactcss3;

var _reactcss2 = _interopRequireDefault(_reactcss);

var _map = _map3;

var _map2 = _interopRequireDefault(_map);

var _merge = _merge3;

var _merge2 = _interopRequireDefault(_merge);

var _color = _color2;

var color = _interopRequireWildcard(_color);

var _common = _common2;

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Twitter = exports.Twitter = function Twitter(_ref) {
  var onChange = _ref.onChange,
      onSwatchHover = _ref.onSwatchHover,
      hex = _ref.hex,
      colors = _ref.colors,
      width = _ref.width,
      triangle = _ref.triangle,
      _ref$styles = _ref.styles,
      passedStyles = _ref$styles === undefined ? {} : _ref$styles,
      _ref$className = _ref.className,
      className = _ref$className === undefined ? "" : _ref$className;
  var styles = (0, _reactcss2.default)((0, _merge2.default)({
    "default": {
      card: {
        width: width,
        background: "#fff",
        border: "0 solid rgba(0,0,0,0.25)",
        boxShadow: "0 1px 4px rgba(0,0,0,0.25)",
        borderRadius: "4px",
        position: "relative"
      },
      body: {
        padding: "15px 9px 9px 15px"
      },
      label: {
        fontSize: "18px",
        color: "#fff"
      },
      triangle: {
        width: "0px",
        height: "0px",
        borderStyle: "solid",
        borderWidth: "0 9px 10px 9px",
        borderColor: "transparent transparent #fff transparent",
        position: "absolute"
      },
      triangleShadow: {
        width: "0px",
        height: "0px",
        borderStyle: "solid",
        borderWidth: "0 9px 10px 9px",
        borderColor: "transparent transparent rgba(0,0,0,.1) transparent",
        position: "absolute"
      },
      hash: {
        background: "#F0F0F0",
        height: "30px",
        width: "30px",
        borderRadius: "4px 0 0 4px",
        float: "left",
        color: "#98A1A4",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      input: {
        width: "100px",
        fontSize: "14px",
        color: "#666",
        border: "0px",
        outline: "none",
        height: "28px",
        boxShadow: "inset 0 0 0 1px #F0F0F0",
        boxSizing: "content-box",
        borderRadius: "0 4px 4px 0",
        float: "left",
        paddingLeft: "8px"
      },
      swatch: {
        width: "30px",
        height: "30px",
        float: "left",
        borderRadius: "4px",
        margin: "0 6px 6px 0"
      },
      clear: {
        clear: "both"
      }
    },
    "hide-triangle": {
      triangle: {
        display: "none"
      },
      triangleShadow: {
        display: "none"
      }
    },
    "top-left-triangle": {
      triangle: {
        top: "-10px",
        left: "12px"
      },
      triangleShadow: {
        top: "-11px",
        left: "12px"
      }
    },
    "top-right-triangle": {
      triangle: {
        top: "-10px",
        right: "12px"
      },
      triangleShadow: {
        top: "-11px",
        right: "12px"
      }
    }
  }, passedStyles), {
    "hide-triangle": triangle === "hide",
    "top-left-triangle": triangle === "top-left",
    "top-right-triangle": triangle === "top-right"
  });

  var handleChange = function handleChange(hexcode, e) {
    color.isValidHex(hexcode) && onChange({
      hex: hexcode,
      source: "hex"
    }, e);
  };

  return _react2.default.createElement("div", {
    style: styles.card,
    className: "twitter-picker " + className
  }, _react2.default.createElement("div", {
    style: styles.triangleShadow
  }), _react2.default.createElement("div", {
    style: styles.triangle
  }), _react2.default.createElement("div", {
    style: styles.body
  }, (0, _map2.default)(colors, function (c, i) {
    return _react2.default.createElement(_common.Swatch, {
      key: i,
      color: c,
      hex: c,
      style: styles.swatch,
      onClick: handleChange,
      onHover: onSwatchHover,
      focusStyle: {
        boxShadow: "0 0 4px " + c
      }
    });
  }), _react2.default.createElement("div", {
    style: styles.hash
  }, "#"), _react2.default.createElement(_common.EditableInput, {
    label: null,
    style: {
      input: styles.input
    },
    value: hex.replace("#", ""),
    onChange: handleChange
  }), _react2.default.createElement("div", {
    style: styles.clear
  })));
};

Twitter.propTypes = {
  width: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
  triangle: _propTypes2.default.oneOf(["hide", "top-left", "top-right"]),
  colors: _propTypes2.default.arrayOf(_propTypes2.default.string),
  styles: _propTypes2.default.object
};
Twitter.defaultProps = {
  width: 276,
  colors: ["#FF6900", "#FCB900", "#7BDCB5", "#00D084", "#8ED1FC", "#0693E3", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"],
  triangle: "top-left",
  styles: {}
};
exports.default = (0, _common.ColorWrap)(Twitter);
export default exports;