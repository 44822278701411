import _react3 from "react";
import _reactcss3 from "reactcss";
import _color2 from "../../helpers/color";
import _common2 from "../common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GoogleFields = undefined;
var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _reactcss = _reactcss3;

var _reactcss2 = _interopRequireDefault(_reactcss);

var _color = _color2;

var color = _interopRequireWildcard(_color);

var _common = _common2;

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var GoogleFields = exports.GoogleFields = function GoogleFields(_ref) {
  var onChange = _ref.onChange,
      rgb = _ref.rgb,
      hsl = _ref.hsl,
      hex = _ref.hex,
      hsv = _ref.hsv;

  var handleChange = function handleChange(data, e) {
    if (data.hex) {
      color.isValidHex(data.hex) && onChange({
        hex: data.hex,
        source: "hex"
      }, e);
    } else if (data.rgb) {
      var values = data.rgb.split(",");
      color.isvalidColorString(data.rgb, "rgb") && onChange({
        r: values[0],
        g: values[1],
        b: values[2],
        a: 1,
        source: "rgb"
      }, e);
    } else if (data.hsv) {
      var _values = data.hsv.split(",");

      if (color.isvalidColorString(data.hsv, "hsv")) {
        _values[2] = _values[2].replace("%", "");
        _values[1] = _values[1].replace("%", "");
        _values[0] = _values[0].replace("\xB0", "");

        if (_values[1] == 1) {
          _values[1] = 0.01;
        } else if (_values[2] == 1) {
          _values[2] = 0.01;
        }

        onChange({
          h: Number(_values[0]),
          s: Number(_values[1]),
          v: Number(_values[2]),
          source: "hsv"
        }, e);
      }
    } else if (data.hsl) {
      var _values2 = data.hsl.split(",");

      if (color.isvalidColorString(data.hsl, "hsl")) {
        _values2[2] = _values2[2].replace("%", "");
        _values2[1] = _values2[1].replace("%", "");
        _values2[0] = _values2[0].replace("\xB0", "");

        if (hsvValue[1] == 1) {
          hsvValue[1] = 0.01;
        } else if (hsvValue[2] == 1) {
          hsvValue[2] = 0.01;
        }

        onChange({
          h: Number(_values2[0]),
          s: Number(_values2[1]),
          v: Number(_values2[2]),
          source: "hsl"
        }, e);
      }
    }
  };

  var styles = (0, _reactcss2.default)({
    "default": {
      wrap: {
        display: "flex",
        height: "100px",
        marginTop: "4px"
      },
      fields: {
        width: "100%"
      },
      column: {
        paddingTop: "10px",
        display: "flex",
        justifyContent: "space-between"
      },
      double: {
        padding: "0px 4.4px",
        boxSizing: "border-box"
      },
      input: {
        width: "100%",
        height: "38px",
        boxSizing: "border-box",
        padding: "4px 10% 3px",
        textAlign: "center",
        border: "1px solid #dadce0",
        fontSize: "11px",
        textTransform: "lowercase",
        borderRadius: "5px",
        outline: "none",
        fontFamily: "Roboto,Arial,sans-serif"
      },
      input2: {
        height: "38px",
        width: "100%",
        border: "1px solid #dadce0",
        boxSizing: "border-box",
        fontSize: "11px",
        textTransform: "lowercase",
        borderRadius: "5px",
        outline: "none",
        paddingLeft: "10px",
        fontFamily: "Roboto,Arial,sans-serif"
      },
      label: {
        textAlign: "center",
        fontSize: "12px",
        background: "#fff",
        position: "absolute",
        textTransform: "uppercase",
        color: "#3c4043",
        width: "35px",
        top: "-6px",
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        fontFamily: "Roboto,Arial,sans-serif"
      },
      label2: {
        left: "10px",
        textAlign: "center",
        fontSize: "12px",
        background: "#fff",
        position: "absolute",
        textTransform: "uppercase",
        color: "#3c4043",
        width: "32px",
        top: "-6px",
        fontFamily: "Roboto,Arial,sans-serif"
      },
      single: {
        flexGrow: "1",
        margin: "0px 4.4px"
      }
    }
  });
  var rgbValue = rgb.r + ", " + rgb.g + ", " + rgb.b;
  var hslValue = Math.round(hsl.h) + "\xB0, " + Math.round(hsl.s * 100) + "%, " + Math.round(hsl.l * 100) + "%";
  var hsvValue = Math.round(hsv.h) + "\xB0, " + Math.round(hsv.s * 100) + "%, " + Math.round(hsv.v * 100) + "%";
  return _react2.default.createElement("div", {
    style: styles.wrap,
    className: "flexbox-fix"
  }, _react2.default.createElement("div", {
    style: styles.fields
  }, _react2.default.createElement("div", {
    style: styles.double
  }, _react2.default.createElement(_common.EditableInput, {
    style: {
      input: styles.input,
      label: styles.label
    },
    label: "hex",
    value: hex,
    onChange: handleChange
  })), _react2.default.createElement("div", {
    style: styles.column
  }, _react2.default.createElement("div", {
    style: styles.single
  }, _react2.default.createElement(_common.EditableInput, {
    style: {
      input: styles.input2,
      label: styles.label2
    },
    label: "rgb",
    value: rgbValue,
    onChange: handleChange
  })), _react2.default.createElement("div", {
    style: styles.single
  }, _react2.default.createElement(_common.EditableInput, {
    style: {
      input: styles.input2,
      label: styles.label2
    },
    label: "hsv",
    value: hsvValue,
    onChange: handleChange
  })), _react2.default.createElement("div", {
    style: styles.single
  }, _react2.default.createElement(_common.EditableInput, {
    style: {
      input: styles.input2,
      label: styles.label2
    },
    label: "hsl",
    value: hslValue,
    onChange: handleChange
  })))));
};

exports.default = GoogleFields;
export default exports;