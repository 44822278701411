import _react3 from "react";
import _propTypes3 from "prop-types";
import _reactcss3 from "reactcss";
import _merge3 from "lodash/merge";
import _common2 from "../common";
import _HuePointer3 from "./HuePointer";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HuePicker = undefined;

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _propTypes = _propTypes3;

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactcss = _reactcss3;

var _reactcss2 = _interopRequireDefault(_reactcss);

var _merge = _merge3;

var _merge2 = _interopRequireDefault(_merge);

var _common = _common2;
var _HuePointer = _HuePointer3;

var _HuePointer2 = _interopRequireDefault(_HuePointer);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var HuePicker = exports.HuePicker = function HuePicker(_ref) {
  var width = _ref.width,
      height = _ref.height,
      onChange = _ref.onChange,
      hsl = _ref.hsl,
      direction = _ref.direction,
      pointer = _ref.pointer,
      _ref$styles = _ref.styles,
      passedStyles = _ref$styles === undefined ? {} : _ref$styles,
      _ref$className = _ref.className,
      className = _ref$className === undefined ? "" : _ref$className;
  var styles = (0, _reactcss2.default)((0, _merge2.default)({
    "default": {
      picker: {
        position: "relative",
        width: width,
        height: height
      },
      hue: {
        radius: "2px"
      }
    }
  }, passedStyles)); // Overwrite to provide pure hue color

  var handleChange = function handleChange(data) {
    return onChange({
      a: 1,
      h: data.h,
      l: 0.5,
      s: 1
    });
  };

  return _react2.default.createElement("div", {
    style: styles.picker,
    className: "hue-picker " + className
  }, _react2.default.createElement(_common.Hue, _extends({}, styles.hue, {
    hsl: hsl,
    pointer: pointer,
    onChange: handleChange,
    direction: direction
  })));
};

HuePicker.propTypes = {
  styles: _propTypes2.default.object
};
HuePicker.defaultProps = {
  width: "316px",
  height: "16px",
  direction: "horizontal",
  pointer: _HuePointer2.default,
  styles: {}
};
exports.default = (0, _common.ColorWrap)(HuePicker);
export default exports;