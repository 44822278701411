import _react3 from "react";
import _reactcss3 from "reactcss";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SliderPointer = undefined;
var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _reactcss = _reactcss3;

var _reactcss2 = _interopRequireDefault(_reactcss);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var SliderPointer = exports.SliderPointer = function SliderPointer(_ref) {
  var direction = _ref.direction;
  var styles = (0, _reactcss2.default)({
    "default": {
      picker: {
        width: "18px",
        height: "18px",
        borderRadius: "50%",
        transform: "translate(-9px, -1px)",
        backgroundColor: "rgb(248, 248, 248)",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)"
      }
    },
    "vertical": {
      picker: {
        transform: "translate(-3px, -9px)"
      }
    }
  }, {
    vertical: direction === "vertical"
  });
  return _react2.default.createElement("div", {
    style: styles.picker
  });
};

exports.default = SliderPointer;
export default exports;